import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 10 min. `}<meta itemProp="prepTime" content="PT10M" /></h5>
    <h5>{`Cook time: 60 min. `}<meta itemProp="cookTime" content="PT60M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`3-4`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Potatoes, cubed</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Olive oil</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Herbs</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Baking sheet</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Cube, arrange, and dress potatoes</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`350F for 45-50 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`375F for 35-40 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`400F for 30 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`450F for 20-25 minutes.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      